.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
  z-index: 0;
  /*transform: scale(1);*/
}

.fade-exit.fade-exit-active {
  opacity: 0;
  /*transform: scale(0.9);*/
  transition: opacity 250ms ease-out;
}

.mainContent {
    margin: 1em;
    position: relative;
}

.subContent {
    position: absolute;
}